import * as icons from "assets";

export type TIcon =
  | "color-advertisement"
  | "color-building"
  | "color-calendar"
  | "color-car"
  | "color-compass"
  | "color-doc"
  | "color-edit"
  | "color-find"
  | "color-geo"
  | "color-notification"
  | "color-settings"
  | "color-user-list"
  | "color-user"
  | "color-warning"
  | "color-battery-0"
  | "color-battery-25"
  | "color-battery-50"
  | "color-battery-75"
  | "color-battery-100"
  | "line-alert-off"
  | "line-back"
  | "line-check"
  | "line-chevron-down"
  | "line-chevron-left"
  | "line-chevron-right"
  | "line-chevron-up"
  | "line-error"
  | "line-eye-cross-line"
  | "line-eye-open"
  | "line-filter"
  | "line-keyboard"
  | "line-list"
  | "line-lock"
  | "line-megaphone"
  | "line-my-location"
  | "line-plus"
  | "line-scan"
  | "line-search"
  | "line-square-arrow-upright"
  | "line-trash-can"
  | "line-user"
  | "line-warning-triangle"
  | "line-x-mark"
  | "line-clock"
  | "line-reload"
  | "line-arrow-up"
  | "line-arrow-down"
  | "social-apple"
  | "social-google"
  | "social-kakao"
  | "social-naver"
  | "solid-alert-off"
  | "solid-building"
  | "solid-camera"
  | "solid-car"
  | "solid-check-circle"
  | "solid-doc-list"
  | "solid-error-circle"
  | "solid-fire"
  | "solid-info"
  | "solid-machine"
  | "solid-map"
  | "solid-minus-circle"
  | "solid-pen"
  | "solid-phone"
  | "solid-user"
  | "solid-warning"
  | "solid-x-mark-circle";

type TSize = "xs" | "sm" | "md" | "lg" | "xl";

interface IIconProps {
  icon: TIcon;
  size: TSize;
  fill?: string;
}

export default function Icon({ icon, size = "md", fill }: IIconProps) {
  if (!icon) return null;
  if (
    !icon.startsWith("color-") &&
    !icon.startsWith("line-") &&
    !icon.startsWith("social-") &&
    !icon.startsWith("solid-")
  )
    return null;

  const numberBySize = { xs: 16, sm: 20, md: 24, lg: 32, xl: 48 };
  const args = {
    width: numberBySize[size] + "px",
    height: numberBySize[size] + "px",
  };
  const SVGIcon: any = getFindIcon(icon);
  return <SVGIcon {...args} className={fill ?? ""} />;
}

const getFindIcon = (iconName: string) => {
  switch (iconName) {
    case "color-advertisement":
      return icons.ColorAdvertisement;
    case "color-building":
      return icons.ColorBuilding;
    case "color-calendar":
      return icons.ColorCalendar;
    case "color-car":
      return icons.ColorCar;
    case "color-compass":
      return icons.ColorCompass;
    case "color-doc":
      return icons.ColorDoc;
    case "color-edit":
      return icons.ColorEdit;
    case "color-find":
      return icons.ColorFind;
    case "color-geo":
      return icons.ColorGeo;
    case "color-notification":
      return icons.ColorNotification;
    case "color-settings":
      return icons.ColorSettings;
    case "color-user-list":
      return icons.ColorUserList;
    case "color-user":
      return icons.ColorUser;
    case "color-warning":
      return icons.ColorWarning;
    case "color-battery-0":
      return icons.ColorBattery0;
    case "color-battery-25":
      return icons.ColorBattery25;
    case "color-battery-50":
      return icons.ColorBattery50;
    case "color-battery-75":
      return icons.ColorBattery75;
    case "color-battery-100":
      return icons.ColorBattery100;
    case "line-alert-off":
      return icons.LineAlertOff;
    case "line-back":
      return icons.LineBack;
    case "line-check":
      return icons.LineCheck;
    case "line-chevron-down":
      return icons.LineChevronDown;
    case "line-chevron-left":
      return icons.LineChevronLeft;
    case "line-chevron-right":
      return icons.LineChevronRight;
    case "line-chevron-up":
      return icons.LineChevronUp;
    case "line-error":
      return icons.LineError;
    case "line-eye-cross-line":
      return icons.LineEyeCrossLine;
    case "line-eye-open":
      return icons.LineEyeOpen;
    case "line-filter":
      return icons.LineFilter;
    case "line-keyboard":
      return icons.LineKeyboard;
    case "line-list":
      return icons.LineList;
    case "line-lock":
      return icons.LineLock;
    case "line-megaphone":
      return icons.LineMegaphone;
    case "line-my-location":
      return icons.LineMyLocation;
    case "line-plus":
      return icons.LinePlus;
    case "line-scan":
      return icons.LineScan;
    case "line-search":
      return icons.LineSearch;
    case "line-square-arrow-upright":
      return icons.LineSquareArrowUpright;
    case "line-trash-can":
      return icons.LineTrashCan;
    case "line-user":
      return icons.LineUser;
    case "line-warning-triangle":
      return icons.LineWarningTriangle;
    case "line-x-mark":
      return icons.LineXMark;
    case "line-clock":
      return icons.LineClock;
    case "line-reload":
      return icons.LineReload;
    case "line-arrow-up":
      return icons.LineArrowUp;
    case "line-arrow-down":
      return icons.LineArrowDown;

    case "social-apple":
      return icons.SocialApple;
    case "social-google":
      return icons.SocialGoogle;
    case "social-kakao":
      return icons.SocialKakao;
    case "social-naver":
      return icons.SocialNaver;

    case "solid-alert-off":
      return icons.SolidAlertOff;
    case "solid-building":
      return icons.SolidBuilding;
    case "solid-camera":
      return icons.SolidCamera;
    case "solid-car":
      return icons.SolidCar;
    case "solid-check-circle":
      return icons.SolidCheckCircle;
    case "solid-doc-list":
      return icons.SolidDocList;
    case "solid-error-circle":
      return icons.SolidErrorCircle;
    case "solid-fire":
      return icons.SolidFire;
    case "solid-info":
      return icons.SolidInfo;
    case "solid-machine":
      return icons.SolidMachine;
    case "solid-map":
      return icons.SolidMap;
    case "solid-minus-circle":
      return icons.SolidMinusCircle;
    case "solid-pen":
      return icons.SolidPen;
    case "solid-phone":
      return icons.SolidPhone;
    case "solid-user":
      return icons.SolidUser;
    case "solid-warning":
      return icons.SolidWarning;
    case "solid-x-mark-circle":
      return icons.SolidXMarkCircle;

    default:
      return null;
  }
};
