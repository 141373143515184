import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { toastState } from "stores/toast";
import { IToast } from "stores/toast";
import Icon, { TIcon } from "./Icon";
import { useToast } from "hooks/useToast";

function Toast() {
  const toasts = useRecoilValue(toastState);
  const { removeToast } = useToast();

  const ToastItem = (props: IToast) => {
    const { content, duration, id, type, close } = props;
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      // 나타날 때의 초기 상태 설정
      setIsVisible(true);

      const timeout = setTimeout(() => {
        // 사라질 때의 애니메이션 트리거
        setIsVisible(false);
        setTimeout(() => removeToast(id), 300);
      }, duration ?? 5000);

      return () => clearTimeout(timeout);
    }, [duration, id, removeToast]);

    let bgColorClass = "bg-cluster-danger";
    let iconType: TIcon = "solid-info"; // 기본 값

    if (type === "refresh") {
      bgColorClass = "bg-primary"; // 에러 스타일 클래스 추가
      iconType = "line-reload"; // 에러 아이콘
    }

    return (
      <div
        className={`${bgColorClass} w-[400px] rounded-[5px] p-4 text-white shadow-[0_1px_4px_0_rgba(0,0,0,0.20)] transition-opacity duration-300 ${
          isVisible ? "opacity-100" : "opacity-0"
        }`}
        key={id}
      >
        <div className="flex items-center gap-2 ">
          <Icon icon={iconType} size="md" fill="fill-white" />
          <div className="flex-1 text-subtitle-4">{content}</div>
          {close && (
            <div
              className="cursor-pointer text-subtitle-4"
              onClick={() => {
                // 사라질 때의 애니메이션 트리거
                setIsVisible(false);
                setTimeout(() => removeToast(id), 300);
              }}
            >
              닫기
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="absolute z-50 flex flex-col gap-2 top-10 right-10 ">
      {toasts.map((toast) => (
        <ToastItem {...toast} key={toast.id} />
      ))}
    </div>
  );
}

export default Toast;
