import Loading from "components/commons/Loading";
import React, { Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

export default function PageRoutes() {
  const Login = React.lazy(() => import(`pages/Login`));
  const Iot = React.lazy(() => import(`pages/Iot`));
  const Notice = React.lazy(() => import(`pages/Notice`));
  const User = React.lazy(() => import(`pages/User`));
  const Dashboard = React.lazy(() => import(`pages/Dashboard`));

  const navigate = useNavigate(); // useNavigate 훅을 사용하여 페이지 이동을 제어

  useEffect(() => {
    const autoLogin = localStorage.getItem("autoLogin") === "true";
    const accessToken = localStorage.getItem("accessToken");

    if (autoLogin && accessToken) {
      // 자동 로그인 상태이면 dashboard 페이지로 이동
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="*" element={<Navigate to="/login" />} />
        <Route path={`/login`} element={<Login />} />
        <Route path={`/user`} element={<User />} />
        <Route path={`/iot`} element={<Iot />} />
        <Route path={`/notice`} element={<Notice />} />
        <Route path={`/dashboard`} element={<Dashboard />} />
      </Routes>
    </Suspense>
  );
}
