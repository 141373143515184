import { useRef, useEffect } from "react";
import lottie from "lottie-web";

import loading from "assets/loading/loading.json";

function Loading() {
  const animationContainer = useRef(null);

  useEffect(() => {
    if (animationContainer.current) {
      const animation = lottie.loadAnimation({
        container: animationContainer.current,
        animationData: loading, // JSON 데이터를 저장한 변수 또는 import한 데이터
        renderer: "svg", // 렌더링 방식 (svg, canvas, html)
        loop: true, // 반복 여부
        autoplay: true, // 자동 재생 여부
      });

      // 컴포넌트 언마운트 시 애니메이션 정리
      return () => {
        animation.destroy();
      };
    }
  }, []);

  return (
    <div className="absolute inset-0 z-10 flex items-center justify-center w-full h-full bg-black bg-opacity-70">
      <div ref={animationContainer} className="w-20 h-20 lottie-container" />
    </div>
  );
}

export default Loading;
