import { IToast, toastState } from "stores/toast";
import { useRecoilState } from "recoil";

export function useToast() {
  const [toasts, setToasts] = useRecoilState(toastState);

  const removeToast = (toastID: IToast["id"]) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== toastID));
  };

  const fireToast = (toast: IToast) => {
    setToasts((prev) => [...prev, { ...toast }]);
  };

  return { toasts, fireToast, removeToast };
}
