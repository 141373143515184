import { atom } from "recoil";

export interface IToast {
  id?: string;
  content: string;
  duration?: number;
  type?: "error" | "info" | "success" | "refresh";
  close?: boolean;
}

export const toastState = atom<IToast[]>({
  key: "toastState",
  default: [],
});
