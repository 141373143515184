import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";

import PageRoutes from "PageRoutes";
import Toast from "components/commons/Toast";

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <PageRoutes />
        <Toast />
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
